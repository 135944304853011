/* Summary Section */
.section-summary {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 60px;
  scroll-margin-top: 50px;
}

.summary-content {
  max-width: 800px;
}

.section-summary .section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase;
}

.section-summary .section-title span {
  color: #4285F4; /* Changed from #e63946 to Google blue */
}

.summary-text p {
  margin-bottom: 20px;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
}

@media (max-width: 768px) {
  .section-summary {
    min-height: auto;
    padding-top: 40px;
  }
}

/* Social Links Section */
.social-links-container {
  margin-top: 30px;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.social-item {
  margin: 0 15px;
}

.icon-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2b2d42;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: background-color 0.3s;
}

.icon-circle:hover {
  background-color: #4285F4;
}

.social-icon {
  font-size: 18px;
}

.schedule-btn {
  background-color: #2b2d42;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.btn-icon {
  margin-right: 6px;
}

.schedule-btn:hover {
  background-color: #4285F4;
}
