/* Experience Section */
.section-experience {
  scroll-margin-top: 50px;
}

.timeline {
  position: relative;
  padding-left: 30px;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 6px;
  width: 2px;
  background-color: #ddd;
}

.timeline-item {
  position: relative;
  margin-bottom: 40px;
}

.timeline-marker {
  position: absolute;
  left: -30px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #4285F4; /* Changed from #e63946 to Google blue */
  border: 3px solid white;
  box-shadow: 0 0 0 2px #ddd;
}

.timeline-header {
  margin-bottom: 15px;
}

.timeline-header h3 {
  font-size: 1.3rem;
  margin-bottom: 5px;
}

.timeline-meta {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #666;
  font-size: 0.9rem;
}

.timeline-body ul {
  list-style: none;
  padding-left: 20px;
}

.timeline-body li {
  position: relative;
  margin-bottom: 10px;
}

.timeline-body li::before {
  content: '•';
  position: absolute;
  left: -20px;
  color: #4285F4; /* Changed from #e63946 to Google blue */
  font-weight: bold;
}

@media (max-width: 768px) {
  .timeline-meta {
    flex-direction: column;
    gap: 5px;
  }
}

.technologies {
  margin-top: 20px;
}

.technologies h4 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tech-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 8px;
  padding: 5px 12px;
  background-color: #f5f5f5;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #333;
  transition: all 0.3s ease;
}

.tech-tag:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.tech-tag svg {
  margin-right: 5px;
  font-size: 1.2em;
  color: #4285F4;
  line-height: 1;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
}

.tech-name {
  margin-left: 4px;
  line-height: 1;
  vertical-align: middle;
}

/* Skills Section */
.section-skills {
  margin-top: 50px;
  padding: 40px 0;
  background-color: #f9f9f9;
  scroll-margin-top: 50px;
}

.skills-container {
  max-width: 1000px;
  margin: 0 auto;
}

.skills-intro {
  text-align: center;
  margin-bottom: 30px;
}

.skills-intro p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

.skills-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
}

.skill-tag {
  background-color: #4285F4;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  display: inline-block;
  transition: all 0.3s;
  cursor: default;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.skill-tag:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  background-color: #3367d6;
}

@media (max-width: 768px) {
  .skills-tags {
    gap: 8px;
  }
  
  .skill-tag {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}
