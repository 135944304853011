/* Skills Section */
.section-skills {
  scroll-margin-top: 50px;
}

.skills-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.skill-group {
  margin-bottom: 30px;
}

.skill-group-title {
  font-size: 1.3rem;
  margin-bottom: 15px;
  color: #333;
  position: relative;
  padding-bottom: 8px;
}

.skill-group-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: #4285F4;
}

.skill-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skill-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #f5f5f5;
  border-radius: 30px;
  font-size: 0.9rem;
  color: #333;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
}

.skill-tag:hover {
  background-color: #e8e8e8;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.skill-tag svg {
  margin-right: 8px;
  font-size: 1.2em;
  line-height: 1;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
}

.skill-name {
  margin-left: 4px;
  line-height: 1;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .skill-tag {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
  
  .skill-tag svg {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .skill-list {
    gap: 8px;
  }
  
  .skill-tag {
    padding: 6px 12px;
    font-size: 0.85rem;
  }
}
