/* Base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 50px; /* Add padding for fixed header */
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  overflow-x: hidden;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 30px;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s;
}

/* Layout Structure */
.layout-wrapper {
  display: flex;
  min-height: 100vh;
}

/* Main Content Styles */
.main-content {
  flex: 1;
  margin-left: 280px;
  width: calc(100% - 280px);
  padding-top: 0; /* Changed from 50px to 0 to allow sections to control their own padding */
}

section {
  padding: 60px 0;
  border-bottom: 1px solid #eee;
}

section:last-child {
  border-bottom: none;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: #4285F4; /* Changed from #e63946 to Google blue */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .main-content {
    margin-left: 240px;
    width: calc(100% - 240px);
  }
}

@media (max-width: 768px) {
  .layout-wrapper {
    flex-direction: column;
  }

  .main-content {
    margin-left: 0;
    width: 100%;
    padding-top: 30px;
  }

  .container {
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.7rem;
  }
}


@media print {
  .no-print {
    display: none !important;
  }
}