/* Sidebar Styles */
.sidebar {
  width: 280px;
  background-color: #1a2238; /* Dark blue background */
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center; /* Added for better vertical centering */
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; /* Added to ensure full width */
  padding: 40px 30px;
}

.sidebar-header {
  margin-bottom: 40px; /* Adjusted margin for better vertical spacing */
  text-align: center;
}

.logo h1 {
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
}

.logo span {
  color: #e63946;
}

.profile-image {
  width: 150px;
  height: 150px;
  margin: 0 auto 20px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid rgba(255, 255, 255, 0.3); /* Slightly brighter border */
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sidebar-info {
  margin-top: 10px; /* Reduced margin for better spacing */
}

.sidebar-section {
  margin-bottom: 40px;
}

.sidebar-section h3 {
  font-size: 1rem;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #4285F4; /* Changed from #FF6B6B to Google blue */
  font-weight: 600;
}

.sidebar-section:last-child {
  margin-bottom: 0; /* Reduced bottom margin for the last section */
}

.contact-list,
.sidebar-nav ul {
  list-style: none;
  padding: 0;
}

.contact-list li,
.sidebar-nav li {
  margin-bottom: 15px;
}

.contact-list a,
.sidebar-nav a {
  display: flex;
  align-items: center;
  color: #E0E0E0; /* Much lighter text color for better readability */
  font-size: 0.9rem;
  padding: 5px 0;
  position: relative;
  transition: color 0.3s ease;
}

.sidebar-nav a.active {
  color: #4285F4; /* Use Google blue for active link */
  font-weight: 500;
}

.sidebar-nav a.active::before {
  content: "";
  position: absolute;
  left: -10px;
  width: 4px;
  height: 100%;
  background-color: #4285F4;
  border-radius: 2px;
}

.contact-list li span,
.sidebar-nav li span {
  color: #E0E0E0; /* Lighter text for non-link elements */
}

.contact-list a:hover,
.sidebar-nav a:hover {
  color: #4285F4; /* Changed from #FF6B6B to Google blue */
}

.icon {
  margin-right: 10px;
  display: inline-block;
  width: 20px;
  text-align: center;
  color: #4285F4; /* Changed from #FF6B6B to Google blue */
}

.print-link {
  /* Remove special styling to make it match other menu items */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .sidebar {
    width: 240px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    position: relative;
    width: 100%;
    height: auto;
    min-height: auto;
    box-shadow: none;
    align-items: flex-start; /* Reset alignment for mobile */
  }

  .sidebar-content {
    padding: 30px 20px;
  }
}

@media (max-width: 480px) {
  .sidebar-header {
    margin-bottom: 20px;
  }
}

/* Print styles */
@media print {
  .no-print {
    display: none !important;
  }
  
  .sidebar {
    position: relative;
    height: auto;
    width: 100%;
    box-shadow: none;
    overflow: visible;
  }
  
  .sidebar-content {
    display: flex;
    flex-direction: row;
    padding: 20px;
  }
  
  .print-inline {
    display: inline-block;
    vertical-align: top;
  }
  
  .sidebar-header.print-inline {
    width:  25%;
    margin-right: 5%;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    height: 100%; /* Take full height of parent */
  }
  
  .sidebar-section.print-inline {
    width: 75%;
  }
  
  /* More aggressive fix for contact items */
  .contact-list {
    width: 100%;
  }
  
  .contact-list li {
    margin-bottom: 5px;
    width: 100%;
    clear: both;
  }
  
  .contact-list a {
    display: block; /* Change to block instead of flex */
    white-space: nowrap; /* Prevent wrapping at all costs */
    font-size: 0.8rem; /* Even smaller text */
    width: 100%;
  }
  
  .contact-list .icon {
    display: inline-block;
    width: 14px;
    min-width: 14px; /* Ensure the icon doesn't shrink */
    margin-right: 5px; /* Reduce spacing after icon */
    text-align: center;
  }
  
  .profile-image {
    width: 150px; /* Even larger image */
    height: 150px; /* Even larger image */
    margin: 0 auto; /* Center horizontally */
  }
  
  /* Center logo text if present */
  .logo {
    text-align: center;
  }
  
  /* Skills section starts on a new page */
  #skills-section,
  .section-skills {
    page-break-before: always;
    break-before: page;
  }
}
