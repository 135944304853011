/* Interests Section */
.section-interests {
  scroll-margin-top: 50px;
}

.interests-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.interest-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-left: 3px solid #34A853; /* Google green for a different accent */
  transition: transform 0.3s;
  width: 100%;
}

.interest-card:hover {
  transform: translateY(-5px);
}

.interest-details h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.interest-description {
  color: #555;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .interests-content {
    /* Already column-based, no changes needed for mobile */
  }
}
