/* Education Section */
.section-education {
  scroll-margin-top: 50px;
}

.education-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.education-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-left: 3px solid #4285F4; /* Changed from #e63946 to Google blue */
  transition: transform 0.3s;
}

.education-card:hover {
  transform: translateY(-5px);
}

.education-details h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.institution {
  font-weight: 600;
  color: #555;
  margin-bottom: 5px;
}

.period {
  color: #777;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .education-content {
    grid-template-columns: 1fr;
  }
}
